import {observer} from "mobx-react";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import ExchangeRatesStore from "./exchange.rates.store";
import {useEffect, useState} from "react";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {Filters} from "../../styles/filters";
import FilterView from "../../components/filters/filters.view";
import {FilterButton} from "../../styles/button";
import {AddButton, TableHeader} from "../../styles/table-header";
import TablePaginationView from "../../utils/table.pagination.view";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import ModalView from "../../components/modal/modal.view";
import {ExchangeRateModel} from "./exchange.rates.model";
import ExchangeRatesModalView from "./exchange.rates.modal/exchange.rates.modal.view";

const ExchangeRatesView = observer(() => {
    const {isNavOpen} = AppStore;

    const {
        exchangeRatesLoading,
        exchangeRates,
        exchangeRateFilters,
        exchangeRatesPageInit,
        setExchangeRateFilters,
        currenciesReq,
        currenciesLoading,
        currencies
    } = ExchangeRatesStore;

    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const currenciesOptions = currencies.map(
        ({code}) => ({label: code, value: code})
    )

    const [filterModal, setFilterModal] = useState(false);
    const [createEditModal, setCreateEditModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ExchangeRateModel | null>(null)

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('EXCHANGE_RATES', 'TABLE HEADERS', controller.signal);
        void exchangeRatesPageInit(controller.signal);

        return () => controller.abort();
    }, [destroyDictionary, dictionaryListReq, exchangeRatesPageInit]);

    const handleFilterChange = (e: any, objectKey: string, index?: number, antSelect?: boolean) => {
        if (objectKey === 'page' && index !== undefined) {
            setExchangeRateFilters({page: index + 1});
            return;
        }

        if (!antSelect) {
            const {
                target: {options, selectedIndex},
            } = e;

            setExchangeRateFilters({[objectKey]: options[selectedIndex].value});
        } else {
            setExchangeRateFilters({[objectKey]: e});
        }
    };

    const setDates = (e: any) => {
        if (e !== null) {
            setExchangeRateFilters({date_to: e[1].format('YYYY-MM-DD')});
            setExchangeRateFilters({date_from: e[0].format('YYYY-MM-DD')});
        } else {
            setExchangeRateFilters({date_from: ''});
            setExchangeRateFilters({date_to: ''});
        }
    };

    const prevPage = () => {
        if (exchangeRateFilters.page! > 1) {
            setExchangeRateFilters({page: exchangeRateFilters.page! - 1});
        }
    };

    const nextPage = () => {
        if (exchangeRateFilters.page! < exchangeRateFilters.pages!) {
            setExchangeRateFilters({page: exchangeRateFilters.page! + 1});
        }
    };

    const pageLimits = [25, 50, 100, 250, 500];

    const filterInputs = [
        {
            type: 'range_picker',
        },
        {
            fieldName: 'currency_code',
            placeholder: 'Все валюты',
            type: 'select',
            options: currenciesOptions,
            hasSearch: false,
            loading: currenciesLoading,
            onFocus: () => currenciesReq(),
        },
    ];

    const handleClickAddButton = () => {
        setInitialValue(null);
        setCreateEditModal(true);
    }

    const handleCloseCreateEditModal = () => {
        setCreateEditModal(false);
        setInitialValue(null);
    }

    return (
        <Page isNavOpen={isNavOpen} fadeIn vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('EXCHANGE_RATES')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={exchangeRateFilters}
                        handleFilterChange={handleFilterChange}
                        setDates={setDates}
                    />
                </Filters>
                <FilterButton
                    id={'exchange-rates-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton onClick={handleClickAddButton}>
                    Создать
                </AddButton>
            </TableHeader>
            <TableDesktopView data={exchangeRates} headers={dictionaryList} loading={exchangeRatesLoading}/>
            <TableMobileView data={exchangeRates} headers={dictionaryList} loading={exchangeRatesLoading}/>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={exchangeRateFilters.pages}
                currentPage={exchangeRateFilters.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {filterModal && (
                <ModalView title={'Фильтры'} onClose={() => setFilterModal(false)}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={exchangeRateFilters}
                        handleFilterChange={handleFilterChange}
                        setDates={setDates}
                    />
                </ModalView>
            )}
            {createEditModal && (
                <ModalView
                    title={initialValue ? 'Имзенить' : 'Создать'}
                    onClose={handleCloseCreateEditModal}
                >
                    <ExchangeRatesModalView initialValues={initialValue} setModal={setCreateEditModal}/>
                </ModalView>
            )}
        </Page>
    );
});

export default ExchangeRatesView;