import logo from '../../assets/icons/logout.svg';
import businessSettings from '../../assets/icons/settings-business.svg';
import { SubMenuWrap } from '../../pages/main/logout.style';
import AppStore from '../../app.store';

interface SubMenuProps {
  handleLogout: (e: { stopPropagation: () => void }) => void;
  handleProfileStore: (e: { stopPropagation: () => void }) => void;
}

function SubMenu({
  handleLogout,
  handleProfileStore,
}: SubMenuProps) {
  const { userData } = AppStore;

  return (
    <SubMenuWrap>
      <div className="sub-menu-wrap" id="subMenu">
        <div className="sub-menu">
          <div className="user-info">
            <div
              className="user-name"
              style={{
                background:
                  'linear-gradient(90deg,rgb(30 64 109) 0%,rgb(89 136 197) 100%)',
              }}
            >
              {userData?.name[0]}
            </div>
            <h3>{userData?.full_name}</h3>
          </div>
          <hr />
          <div className="sub-menu-link" onClick={handleProfileStore}>
            <img src={businessSettings} alt="businessSettings" />
            <p>Профиль</p>
          </div>
          <div className="sub-menu-link" onClick={handleLogout}>
            <img src={logo} alt="logo" />
            <p>Выход</p>
          </div>
        </div>
      </div>
    </SubMenuWrap>
  );
}

export default SubMenu;
