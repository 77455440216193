import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios, {GenericAbortSignal} from "axios";
import {api} from "../../../api/endpoints";
import {DeleteItem} from "../../../components/confirm/confirm.delete";
import transactionsStore from "../../transactions/transactions.store";
import {Serials, TransactionsModalModel} from "./transactions.modal.model";

const {businessSettings} = AppStore;

const defaultBusinessUnitId: number = AppStore.userData?.business_unit_id! > 1 ? AppStore.userData?.business_unit_id! : 0;

const formInitialState: any = {
    amount: 0,
    business_unit: '',
    date: '',
    details: '',
    id: 0,
    measure_unit: '',
    product: '',
    quantity: 0,
    status: '',
    user: '',
    client: '',
    account: '',
    formatted_date: '',
    trans_type: '',
    payment_method: '',
    cost: 0,
    prod_type_id: 0,
    commission: 0,
    status_color: '',
    status_id: 0,
    business_unit_id: defaultBusinessUnitId,
    client_id: 1,
    payment_method_id: 1,
    product_id: 0,
    account_id: 1,
    type_id: 1,
    products: [],
    clients: [],
    owner_transaction_id: 0,
    trans_type_id: 1,
    recomdended_price: 0,
    set_price: 0,
    bu_id: defaultBusinessUnitId,
    total_amount: 0,
    can_update: false,
    can_execute: false,
    starting_status: false,
    employee_id: 0,
    total_quantity: 0,
    total_goods: 0,
    rate: 1,
    total_amount_selling_currency: 0,
}
const loadingInitialState: boolean = false;
const productListInitialState: TransactionsModalModel[] = []

class TransactionsModalStore {
    public form = formInitialState;
    public loading = loadingInitialState;
    public message: string = '';
    public error: string = '';
    public productList = productListInitialState;
    public confirmDuplicateOperationModal: DeleteItem = {isModalOpen: false};
    public transactionGoods = transactionsStore;
    public transactionServices = transactionsStore;
    public paymentMethods: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public destroyTransactionModal = () => {
        this.loading = loadingInitialState;
        this.form = formInitialState;
        this.message = '';
        this.error = '';
        this.productList = [];
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    public setFormField = (field: string, value: any) => {
        // @ts-ignore
        this.form[field] = value;
    }

    public addProductSerials = (index: number, serials: Serials[]) => {
        this.productList[index].serials.push(...serials);
    }

    public addProductToList = (product: TransactionsModalModel) => {
        this.productList.push(product);
        this.form.total_amount = 0;
        this.form.total_quantity = 0;
        this.form.total_goods = 0;
        this.form.total_services = 0;
        this.productList.map((product: any) => {
            this.form.total_amount += product.total_amount
            this.form.total_quantity += product.quantity
            this.form.total_amount_selling_currency += product.total_amount_selling_currency
            if (product.type === 'goods') {
                this.form.total_goods += product.quantity
            } else if (product.type === 'service') {
                this.form.total_services += product.quantity
            }
        })
    }

    public clearProductSerials = (prodIndex: number) => {
        this.productList[prodIndex].serials = []
    }

    public deleteProductFromList = (index: number) => {
        this.productList.splice(index, 1);
        this.form.total_amount = 0;
        this.form.total_quantity = 0;
        this.form.total_goods = 0;
        this.form.total_services = 0;
        this.productList.map((product: any) => {
            this.form.total_amount += product.total_amount
            this.form.total_quantity += product.quantity
            if (product.type === 'goods') {
                this.form.total_goods += product.quantity
            } else if (product.type === 'service') {
                this.form.total_services += product.quantity
            }
        })
    }

    setConfirmDuplicateOperationModal = (value: boolean) => {
        this.confirmDuplicateOperationModal.isModalOpen = value;
    }

    public handleSelectProduct = (object: any, type: string) => {
        object.summ = object.quantity;
        object.set_price = object.price;
        object.product_id = object.id;
        object.totalSumm = object.total;
        object.type = type;
        object.has_serial = object.serial_number != ''
        object.amount = object.price
        object.total_amount = object.total
        this.addProductToList(object);
    };

    public transactionProducts = () => {
        this.transactionServices.transactionServices?.forEach((item) =>
            this.handleSelectProduct(item, 'service')
        );
        this.transactionGoods.transactionGoods?.forEach((item) =>
            this.handleSelectProduct(item, 'goods')
        );
    };

    private getToday = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(today.getDate()).padStart(2, '0'); // Ensure two-digit day

        return `${year}-${month}-${day}`;
    }

    public initCreateOperation = async (initialValue: any) => {
        if (initialValue) {
            const {transactionProductsReq} = transactionsStore;
            await transactionProductsReq(initialValue?.id!);
            this.form.id = initialValue.id;
            this.form.bu_id = initialValue?.business_unit_id!;
            this.form.client_id = initialValue.client_id!;
            this.form.payment_method_id = initialValue.payment_method_id!;
            this.form.details = initialValue.details;
            this.form.account = initialValue.account!;
            this.transactionProducts();
        }
    }

    private transformProductsToResultArray = (products: TransactionsModalModel[]) => {
        return products.flatMap((product) => {
            if (product.has_serial) {
                // When the product has serials
                return product.serials.map((serial) => ({
                    product_id: product.id,
                    quantity: 1, // Each serial corresponds to one unit
                    serial_id: serial.id,
                    account: product.account,
                    amount: product.total_amount,
                    total_summ: product.total_amount,
                    commission_from_amount: Boolean(product.commission_from_amount),
                    selling_currency_rate: Number((this.form.rate).toFixed(4)),
                    selling_currency_price: product.selling_currency_price,
                    selling_currency_code: businessSettings?.selling_currency,
                }));
            } else {
                // When the product does not have serials
                return [
                    {
                        product_id: product.id,
                        quantity: Number(product.quantity), // Use the product's quantity
                        serial_id: 0, // No serial
                        account: product.account,
                        amount: product.total_amount,
                        total_summ: product.total_amount,
                        commission_from_amount: Boolean(product.commission_from_amount),
                        selling_currency_rate: Number((this.form.rate).toFixed(4)),
                        selling_currency_price: Number(product.total_amount_selling_currency.toFixed(2)),
                        selling_currency_code: businessSettings?.selling_currency,
                    },
                ];
            }
        });
    };

    public createOperationReq = async (edit?: boolean, confirmDuplicateOperation?: boolean) => {
        this.setLoading(true);

        const {businessSettings} = AppStore;

        if (businessSettings?.product_min_max) {
            for (let i = 0; i < this.productList.length; i++) {
                if (this.productList[i].type === 'service') {
                    if (this.productList[i].amount > this.productList[i].max || this.productList[i].amount <= this.productList[i].min) {
                        this.error = 'Сумма вне диапазона от мин до макс суммы'
                        toast.error(this.error);
                        this.setLoading(false);  // Set isLoading to false before return
                        return; // Stop and exit the function
                    }
                }
            }
        }

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken(),
        };

        const payload = {
            id: Number(this.form.id),
            bu_id: Number(this.form.bu_id),
            client_id: Number(this.form.client_id),
            account_id: Number(this.form.account_id),
            total_amount: Number(this.form.total_amount),
            details: this.form.details,
            payment_method_id: Number(this.form.payment_method_id),
            account: this.form.account,
            type_id: Number(this.form.type_id),
            employee_id: Number(this.form.employee_id),
            selling_currency: businessSettings?.selling_currency,
            main_currency: businessSettings?.main_currency,
            rate: this.form.rate,
            //
            paying_client_phone: this.form.paying_client_phone,
            paying_client_full_name: this.form.paying_client_full_name,
            paying_client_date_of_birth: this.form.paying_client_date_of_birth,
            paying_client_passport_number: this.form.paying_client_passport_number,
            paying_client_passport_issuing_authority: this.form.paying_client_passport_issuing_authority,
            paying_client_passport_date_of_issue: this.form.paying_client_passport_date_of_issue,
            paying_client_passport_date_of_expiry: this.form.paying_client_passport_date_of_expiry,
            pass_duplicate: confirmDuplicateOperation,
            //
            products: this.transformProductsToResultArray(this.productList),
        };

        try {
            let response;
            if (edit) {
                response = await axios.put(api.transactions, payload, {
                    headers,
                });
            } else {
                let requestUrl = api.transactions

                if (confirmDuplicateOperation) {
                    requestUrl += '?pass_duplicate=' + confirmDuplicateOperation
                }

                response = await axios.post(requestUrl, payload, {
                    headers,
                });
            }

            this.message = response.data.message;
            toast.success(this.message);
            //  void this.transactions.transactionsReq();

            return response;
        } catch (error: any) {
            if (error.response.status == 409) {
                this.setConfirmDuplicateOperationModal(true);
                return;
            }

            this.error = error?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    };

    setPaymentMethods(data: any) {
        this.paymentMethods = data;
    }

    public paymentMethodsReq = async (signal?: GenericAbortSignal) => {
        this.setLoading(true);

        try {
            const {getToken} = AppStore;

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            };

            const response = await axios.get(api.payment_methods, {
                headers,
                signal,
            });
            this.setPaymentMethods(response.data);
        } catch (err) {
        } finally {
            this.setLoading(false);
        }
    };
}

export default new TransactionsModalStore();