import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {ExchangeRateModel} from "../exchange.rates.model";
import ExchangeRatesModalStore from "./exchange.rates.modal.store";
import React, {useEffect} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import ExchangeRatesStore from "../exchange.rates.store";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {Text} from "../../../styles/text";
import dayjs from "dayjs";
import {DatePicker, TimePicker} from "antd";

interface ExchangeRatesModalViewProps {
    initialValues: ExchangeRateModel | null;
    setModal: (isOpen: boolean) => void;
}

const ExchangeRatesModalView = observer(({initialValues, setModal}: ExchangeRatesModalViewProps) => {

    const {
        form,
        createEditExchangeRateReq,
        createExchangeRateLoading,
        setForm,
        destroyExchangeRateFrom,
        initCreateEditExchangeRatePage
    } = ExchangeRatesModalStore;

    const {currencies, currenciesReq, getExchangeRatesReq} = ExchangeRatesStore

    useEffect(() => {
        const controller = new AbortController();
        initCreateEditExchangeRatePage(initialValues)
        void currenciesReq(controller.signal)

        return () => controller.abort()
    }, [initCreateEditExchangeRatePage, currenciesReq]);

    const {base_currency, target_currency, rate, from_date, to_date} = form;

    const currenciesOptions = currencies?.map(
        ({code, name}) => ({label: `${name} (${code})`, value: code})
    )

    const inputs = [
        {
            field: base_currency,
            options: currenciesOptions,
            fieldName: 'base_currency',
            placeholder: 'Базовая валюта',
            type: 'select',
            required: true,
            selectValueType: 'string'
        },
        {
            field: target_currency,
            options: currenciesOptions,
            fieldName: 'target_currency',
            placeholder: 'Целовая валюта',
            type: 'select',
            required: true,
            selectValueType: 'string',
            disabled: true
        },
        {
            field: rate,
            options: rate,
            fieldName: 'rate',
            placeholder: 'Курс',
            type: 'text',
            required: true
        },
    ]

    const handleSubmit = async () => {
        const resp = await createEditExchangeRateReq(!!initialValues)

        if (resp) {
            setModal(false);
            destroyExchangeRateFrom();
            await getExchangeRatesReq();
        }
    }

    const handleInverseRate = () => {
        return (1 / rate).toFixed(4);
    }

    const renderSubmitText = initialValues ? 'Изменить' : 'Создать'

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setForm)}
                <Layout hAlign='space-between'>
                    <Layout widthSize={'35%'}>
                        <Text color={modalTextColor} extent={'S'}> Дата и время начало действия </Text>
                    </Layout>
                    <Layout widthSize={'65%'} between={5}>
                        <Layout widthSize={'63%'}>
                            <DatePicker
                                onChange={(e) => {
                                    setForm({...form, ['from_date']: e?.format('YYYY-MM-DD')})
                                }}
                                style={{
                                    width: '100%',
                                    border: '2px solid #ddd',
                                    borderRadius: '12px'
                                }}
                                placeholder='Выберите дату'
                                value={from_date ? dayjs(from_date) : null}
                            />
                        </Layout>
                        <Layout widthSize={'35%'}>
                            <TimePicker
                                onChange={(e) => {
                                    setForm({...form, ['from_time']: e?.format('hh:mm')})
                                }}
                                style={{
                                    width: '100%',
                                    border: '2px solid #ddd',
                                    borderRadius: '12px'
                                }}
                                format={'hh:mm'}
                                use12Hours={false}
                            />
                        </Layout>
                    </Layout>
                </Layout>
                <Layout hAlign='space-between'>
                    <Layout widthSize={'35%'}>
                        <Text color={modalTextColor} extent={'S'}> Дата и время окончания действия </Text>
                    </Layout>
                    <Layout widthSize={'65%'} between={5}>
                        <Layout widthSize={'63%'}>
                            <DatePicker
                                onChange={(e) => {
                                    setForm({...form, ['to_date']: e?.format('YYYY-MM-DD')})
                                }}
                                style={{
                                    width: '100%',
                                    border: '2px solid #ddd',
                                    borderRadius: '12px'
                                }}
                                placeholder='Выберите дату'
                                value={to_date ? dayjs(to_date) : null}
                            />
                        </Layout>
                        <Layout widthSize={'35%'}>
                            <TimePicker
                                onChange={(e) => {
                                    setForm({...form, ['to_time']: e?.format('hh:mm')})
                                }}
                                style={{
                                    width: '100%',
                                    border: '2px solid #ddd',
                                    borderRadius: '12px'
                                }}
                                format={'hh:mm'}
                                use12Hours={false}
                            />
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>

            <Layout vertical between={5}>
                <Text color={primaryColor} extent={'S'}>
                    {`1 ${base_currency} = ${rate} ${target_currency}`}
                </Text>
                <Text color={primaryColor} extent={'S'}>
                    {`1 ${target_currency} = ${handleInverseRate()} ${base_currency}`}
                </Text>
            </Layout>

            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={createExchangeRateLoading}
                    onClick={handleSubmit}
                    disabled={isValid([inputs])}
                >{createExchangeRateLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default ExchangeRatesModalView;