import styled from "styled-components";
import {borderRadius, primaryColor} from "../utils/variables";

export const CollapsibleContainer = styled.div`
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: ${borderRadius};
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
`;

export const Summary = styled.div`
    background: #f7f7f7;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: ${primaryColor};

    &:hover {
        background: #eaeaea;
    }
`;

export const Content = styled.div<{ isOpen: boolean; contentHeight: number }>`
    max-height: ${({isOpen, contentHeight}) => isOpen ? `${contentHeight}px` : '0'};
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background: #fff;
`;

export const Chevron = styled.span<{ isOpen: boolean }>`
    transition: transform 0.3s ease;
    transform: rotate(${({isOpen}) => (isOpen ? '180deg' : '0')});
    font-size: 0.8rem;
`;