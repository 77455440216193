import {
    TransactionJournalModel,
    TransactionProducts,
    TransactionsFilterModel,
    TransactionsModel,
} from './transactions.model';
import {makeAutoObservable} from 'mobx';
import AppStore from '../../app.store';
import axios, {GenericAbortSignal} from 'axios';
import {api} from '../../api/endpoints';
import {toast} from 'react-toastify';

const today = new Date;
const month = today.getMonth() < 10 ? `0${today.getMonth()}` : `${today.getMonth()}`
const currentDate = `${today.getFullYear()}-${month}-${today.getDate()}`
const transactionsFilterInitialState = {
    date_from: '',
    date_to: '',
    business_unit_id: 0,
    user_id: 0,
    product_type_id: 0,
    category_id: 0,
    brand_id: 0,
    product_id: 0,
    measure_unit_id: 0,
    tran_type_id: 0,
    status: 0,
    page: 1,
    page_limit: 25,
    pages: 0,
    order_by: 'id',
    order_descending: true,
    id: 0,
};

class TransactionsStore {
    public transactions?: TransactionsModel[] = [];
    public transactionJournal?: TransactionJournalModel[] = [];
    public transactionsFilter = transactionsFilterInitialState;
    public transactionGoods?: TransactionProducts[] = [];
    public transactionServices?: TransactionProducts[] = [];
    public reason = '';

    public loading = false;
    public error = '';
    public message = '';
    public prodsLoading = false;

    public transactionsInit = (signal: GenericAbortSignal) => {
        void this.transactionsReq(signal);
    };

    constructor() {
        makeAutoObservable(this);
    }

    setProdsLoading(loading: boolean): void {
        this.prodsLoading = loading;
    }

    setTransactions(data: any) {
        const {transactions} = data;

        this.transactions = transactions;
    }

    setTransactionGoods(data?: any) {
        const {goods} = data;
        this.transactionGoods = goods;
    }

    setTransactionJournal(data: any) {
        const {journal} = data;
        this.transactionJournal = journal;
    }

    setTransactionServices(data?: any) {
        const {services} = data;
        this.transactionServices = services;
    }

    public transactionsReq = async (signal?: GenericAbortSignal) => {
        const {simpleTableView} = AppStore;
        const type = simpleTableView ? 'simple' : 'extended';
        this.setLoading(true);
        try {
            const {getToken} = AppStore;

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            };
            const filterUrl = Object.keys(this.transactionsFilter).reduce(
                (filters, filter) => {
                    const symbol = filters ? '&' : '?';
                    const filterKeyName = filter as keyof TransactionsFilterModel;
                    if (
                        this.transactionsFilter[filterKeyName] !== 0 &&
                        this.transactionsFilter[filterKeyName] !== ''
                    ) {
                        filters +=
                            symbol + filter + '=' + this.transactionsFilter[filterKeyName];
                    }

                    return filters;
                },
                ''
            );

            if (type === 'simple') {
                const response = await axios.get(api.transactions + filterUrl, {
                    headers,
                    signal,
                });
                this.setTransactions(response.data);
                this.setTransactionsFilterPages(response.data.pages);
            } else {
                const response = await axios.get(api.transactionsExtended + filterUrl, {
                    headers,
                    signal,
                });
                this.setTransactions(response.data);
                this.setTransactionsFilterPages(response.data.pages);
            }
        } catch (err) {
        } finally {
            this.setLoading(false);
        }
    };

    public transactionProductsReq = async (transaction_id: number) => {
        this.setProdsLoading(true);

        const {getToken} = AppStore;

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        };

        try {
            const response = await axios.get(
                api.transaction_products + `?tran_id=${transaction_id}`,
                {headers}
            );

            this.setTransactionGoods(response.data);
            this.setTransactionServices(response.data);
        } catch (err) {
        } finally {
            this.setProdsLoading(false);
        }
    };

    public transactionApproveReq = async (id: number) => {
        this.setLoading(true);
        try {
            const {getToken} = AppStore;

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            };

            const response: any = await axios.put(
                api.transaction_approve + '/' + id,
                {},
                {headers}
            );
            this.message = response?.data.message;
            toast.success(this.message);

            this.setTransactions(response.data);
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    };

    public transactionJournalReq = async (id: number) => {
        try {
            const {getToken} = AppStore;
            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            };

            const response = await axios.get(
                api.transaction_journal + `?trans_id=${id}`,
                {headers}
            );

            this.setTransactionJournal(response.data);
        } catch (err) {
        }
    };

    public setTransactionsFilter = ({
                                        id,
                                        user_id,
                                        business_unit_id,
                                        brand_id,
                                        category_id,
                                        product_type_id,
                                        tran_type_id,
                                        date_from,
                                        date_to,
                                        status,
                                        page,
                                        page_limit,
                                    }: TransactionsFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.transactionsFilter = {
            ...this.transactionsFilter,
            id: isUndefined(id) ? this.transactionsFilter.id : id!,
            user_id: isUndefined(user_id)
                ? this.transactionsFilter.user_id!
                : user_id!,
            business_unit_id: isUndefined(business_unit_id)
                ? this.transactionsFilter.business_unit_id!
                : business_unit_id!,
            brand_id: isUndefined(brand_id)
                ? this.transactionsFilter.brand_id!
                : brand_id!,
            category_id: isUndefined(category_id)
                ? this.transactionsFilter.category_id!
                : category_id!,
            product_type_id: isUndefined(product_type_id)
                ? this.transactionsFilter.product_type_id!
                : product_type_id!,
            tran_type_id: isUndefined(tran_type_id)
                ? this.transactionsFilter.tran_type_id!
                : tran_type_id!,
            date_from: isUndefined(date_from)
                ? this.transactionsFilter.date_from!
                : date_from!,
            date_to: isUndefined(date_to)
                ? this.transactionsFilter.date_to!
                : date_to!,
            status: isUndefined(status) ? this.transactionsFilter.status! : status!,
            page: isUndefined(page) ? this.transactionsFilter.page! : page!,
            page_limit: isUndefined(page_limit)
                ? this.transactionsFilter.page_limit!
                : page_limit!,
        };

        if (page_limit) {
            this.transactionsFilter.page = 1;
        }

        void this.transactionsReq();
    };

    setTransactionsFilterPages(pages: number) {
        this.transactionsFilter.pages = pages;
    }

    private setLoading(loadingState: boolean) {
        this.loading = loadingState;
    }

    public destroyProds = () => {
        this.transactionServices = [];
        this.transactionGoods = [];
    };

    public destroyJournal = () => {
        this.transactionJournal = [];
    };

    public clearFilter = () => {
        this.transactionsFilter = transactionsFilterInitialState;
    };
}

export default new TransactionsStore();
