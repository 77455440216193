import {CurrencyModel, ExchangeRateFilterModel, ExchangeRateModel} from "./exchange.rates.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";

const exchangeRatesInitialState: ExchangeRateModel[] = [];
const loadingInitialState: boolean = false;
const currenciesInitialState: CurrencyModel[] = [];
const filtersInitialState: ExchangeRateFilterModel = {
    currency_code: '',
    date_from: '',
    date_to: '',

    page: 1,
    page_limit: 25,
    pages: 1,
    order_by: '',
    order_descending: true,
}

class ExchangeRatesStore {
    public exchangeRatesLoading = loadingInitialState;
    public exchangeRates = exchangeRatesInitialState;
    public exchangeRateFilters = filtersInitialState;
    public currencies = currenciesInitialState;
    public currenciesLoading = loadingInitialState;

    constructor() {
        makeAutoObservable(this);
    }

    public exchangeRatesPageInit = async (signal: AbortSignal) => {
        await this.getExchangeRatesReq(signal);
    }

    setCurrenciesLoading(loading: boolean) {
        this.currenciesLoading = loading;
    }

    setCurrencies(data: any) {
        const {currencies} = data
        this.currencies = currencies;
    }

    setExchangeRatesLoading(loading: boolean) {
        this.exchangeRatesLoading = loading;
    }

    setExchangeRates(data: any) {
        const {rates} = data;
        this.exchangeRates = rates
    }

    setExchangeRatePages(pages: number) {
        this.exchangeRateFilters.pages = pages;
    }

    public getExchangeRatesReq = async (signal?: AbortSignal) => {
        this.setExchangeRatesLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: "Bearer " + getToken()!,
        }

        const filterUrl = Object.keys(this.exchangeRateFilters).reduce(
            (filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof ExchangeRateFilterModel;

                if (this.exchangeRateFilters[filterKeyName] !== 0 && this.exchangeRateFilters[filterKeyName] !== '') {
                    filters += symbol + filter + '=' + this.exchangeRateFilters[filterKeyName]
                }
                return filters;
            }, '');

        try {
            const response = await axios.get(api.exchange_rates + filterUrl, {headers, signal})
            this.setExchangeRates(response?.data);
            this.setExchangeRatePages(response?.data.pages);
        } catch (e: any) {

        } finally {
            this.setExchangeRatesLoading(false);
        }
    }

    public setExchangeRateFilters = ({
                                         currency_code,
                                         date_from,
                                         date_to,
                                         page,
                                         page_limit,
                                     }: ExchangeRateFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';

        this.exchangeRateFilters = {
            ...this.exchangeRateFilters,
            currency_code: isUndefined(currency_code) ? this.exchangeRateFilters.currency_code : currency_code!,
            date_from: isUndefined(date_from) ? this.exchangeRateFilters.date_from : date_from!,
            date_to: isUndefined(date_to) ? this.exchangeRateFilters.date_to : date_to!,
            page: isUndefined(page) ? this.exchangeRateFilters.page : page!,
            page_limit: isUndefined(page_limit) ? this.exchangeRateFilters.page_limit : page_limit!,
        }

        void this.getExchangeRatesReq();
    }

    public currenciesReq = async (signal?: AbortSignal) => {
        this.setCurrenciesLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: "Bearer " + getToken()!,
        }

        try {
            const response = await axios.get(api.currencies, {headers, signal})
            this.setCurrencies(response?.data)
        } catch (e: any) {

        } finally {
            this.setCurrenciesLoading(false)
        }
    }

    public clearExchangeRateFilters = () => {
        this.exchangeRateFilters = filtersInitialState;
    }
}

export default new ExchangeRatesStore();