import styled from "styled-components";
import {
    navbarSize,
    headerSize,
    navbarMinSize,
    miniNavbarSize,
    media, headerMobileSize, borderRadius,
} from "../utils/variables";
import {Layout} from "./layout";

export const Page = styled(Layout)<{
    isNavOpen: boolean;
    customScrollbar?: boolean;
}>`
    position: absolute;
    right: 0;
    top: ${headerSize}px;
    overflow: auto;
    transition: 250ms;
    max-height: calc(100vh - ${headerSize}px);
    ${({isNavOpen}) =>
            isNavOpen
                    ? `
            width: calc(100vw - ${navbarSize});
            max-width: calc(100vw - ${navbarMinSize});
        `
                    : `
            width: calc(100vw - ${miniNavbarSize});
            max-width: calc(100vw - ${miniNavbarSize});
        `}
    padding: 25px;
    margin-left: 0;
    margin-right: 0;

    ${({customScrollbar}) =>
            customScrollbar ?
                    `
    ::-webkit-scrollbar{
     width: 5px;
     height: 5px;
     display: none;
   }
   ::-webkit-scrollbar-track{
     background-color: #dbdbdb;
     border-radius: ${borderRadius};
   }
   ::-webkit-scrollbar-thumb{
     background-color: #456186;
     border-radius: ${borderRadius};
   }
   ::-webkit-scrollbar-thumb:active{
     background-color: #006eff;
   }
  
  :hover {
    ::-webkit-scrollbar{
      display: inline-table;
    }
  }` : ``}
    ${media.mobile} {
        width: 100vw;
        max-width: 100vw;
        top: ${headerMobileSize + 5}px;
        max-height: calc(100vh - ${headerMobileSize}px);
    }

    ${media.tablet} {
        width: 100vw;
        max-width: 100vw;
        top: ${headerMobileSize + 5}px;
        max-height: calc(100vh - ${headerMobileSize}px);
    }
`;

export const MainPageLayout = styled(Page)<{
    edit: boolean
}>`
    max-height: 200%;
    overflow-y: ${({edit}) => edit ? 'scroll' : 'hidden'};
`;

export const PageView = styled(Layout)<{
    isNavOpen: boolean;
    hasTable?: boolean;
}>`
    right: 0;
    top: ${headerSize}px;
    overflow: auto;
    transition: 250ms;
    max-height: calc(100vh - ${headerSize}px);
    padding: 25px;
    margin-left: 0;
    margin-right: 0;

    ${({hasTable}) =>
            hasTable
                    ? `
            top: 0px;
            position: relative;
            max-height: calc(75vh - ${headerSize}px);
        `
                    : ``
    }
    ${media.mobile} {
        padding: 0;
    }
`;
