import styled, { createGlobalStyle } from 'styled-components';
import { Image } from "../../styles/image";
import { getKeyframes } from "../../utils/getKeyframes";
import {borderRadius, darkBackground, media, primaryColor} from "../../utils/variables";

interface ModalProps {
    hasContext?: boolean;
    mobile?: boolean;
    fullScreen?: boolean;
}

export const ModalContainer = styled.div<ModalProps>`
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.8);
    animation: fadeIn 250ms forwards;
    opacity: .5;
    z-index: 2;
  
    ${getKeyframes('fadeIn')}
`;

export const ModalInner = styled.div<ModalProps>`
    border-radius: ${borderRadius};
    background-color: white;
    max-width: 500px;
    width: 100%;
    animation: scaleIn 250ms forwards;
    ${getKeyframes('scaleIn')}

    ${media.mobile} {
      max-width: 100%;
      height: auto;
      padding-bottom: 100px;
    }
`;

export const AccountsModalInner = styled.div<ModalProps>`
  border-radius: ${borderRadius};
  background-color: white;
  max-width: ${({fullScreen}) => {
    return fullScreen ? `95vw` : `1000px`
  }};
    max-height: ${({fullScreen}) => {
        return fullScreen ? `95vh` : `unset`
    }};
  width: 100%;
  ${({hasContext}) => {
    return hasContext
            ? ''
            : 'animation: scaleIn 250ms forwards'
  }};

  ${media.mobile} {
    max-width: 300px;
  }
`;

export const TransProdsModalInner = styled.div`
    border-radius: ${borderRadius};
    background-color: white;
    max-width: 860px;
    width: 100%;
    animation: scaleIn 250ms forwards;
    ${getKeyframes('scaleIn')}
`;

export const ModalHeader = styled.div`
    width: 100%;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 22px;
    
    ${Image} {
        opacity: .4;
        transition: 250ms;
        cursor: pointer;
        :hover {
            opacity: .8;
        }
    }
`;

export const ModalBody = styled.div<{
    fullScreen?: boolean;
}>`
    width: 100%;
    padding: 22px;
    overflow-y: auto;
    max-height: ${({fullScreen}) => {
        return fullScreen ? `90vh` : `75vh`
    }};
`;

export const Error = styled.span`
    position: relative;
    left: -22px;
    width: calc(100% + 44px);
    padding: 10px 18px;
    background-color: #bb0000;
    color: white;
    text-align: center;
`;

export const ModalGlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
