import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {OwnerSettingsModel} from "./owner.settings.model";
import {makeAutoObservable} from "mobx";
import {toast} from "react-toastify";

const ownerInitialValue: OwnerSettingsModel = {
    active: false,
    created_at: '',
    employee_bonus: false,
    id: 0,
    name: '',
    phone: '',
    reference: 0,
    transaction_approval: false,
    updated_at: '',
    cheque_double_record: false,
    product_commissions: false,
    product_reverse_commission: false,
    changeable_total_amount: false,
    working_hours: false,
    select_employee: false,
    ask_for_paying_client_info: false,
    paying_client_phone: false,
    paying_client_full_name: false,
    paying_client_date_of_birth: false,
    paying_client_passport_number: false,
    paying_client_passport_issuing_authority: false,
    paying_client_passport_date_of_issue: false,
    paying_client_passport_date_of_expiry: false,
    duplicate_trn_timeout_seconds: 0,
    refund_period_timeout_days: 0,
    product_min_max: false,
    product_dimensions: false,
    multicurrency: false,
    main_currency: '',
}

const loadingInitialState: boolean = false;

class OwnerSettings {
    public ownerSettings = ownerInitialValue;
    public ownerSettingsLoading = loadingInitialState;
    public ownerSettingsUpdateLoading = loadingInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public ownerSettingsPageInit = async (signal: AbortSignal) => {
        await this.ownerSettingsReq(signal);
    }

    setOwnerSettings(data: any) {
        const {owner} = data;
        this.ownerSettings = owner;
    }

    setLoading(loading: boolean) {
        this.ownerSettingsLoading = loading;
    }

    setUpdateLoading(loading: boolean) {
        this.ownerSettingsUpdateLoading = loading;
    }

    public setOwnerSettingsForm = (form: OwnerSettingsModel) => {
        this.ownerSettings = form;
    }

    public ownerSettingsReq = async (signal?: any) => {
        this.setLoading(true);

        try {
            const {getToken} = AppStore;

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            }

            const response = await axios.get(api.owner, {headers, signal});

            this.setOwnerSettings(response?.data);
        } catch (err) {
            console.error(err);
        } finally {
            this.setLoading(false);
        }
    }

    public changeSettingsReq = async () => {
        this.setUpdateLoading(true);

        const {getToken} = AppStore;

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.put(api.owner, this.ownerSettings, {headers});
            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setUpdateLoading(false);
        }
    }
}

export default new OwnerSettings();