import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {Button} from "../../../styles/button";
import {Select, Spin} from "antd";
import {useEffect, useState} from "react";
import {Text} from "../../../styles/text";
import ProductSerialNumbersStore from "../../product_serial_numbers/product_serial_numbers.store";
import TransactionsModalStore from "./transactions.modal.store";
interface TransactionsModalSerialsViewProps {
    initialValue: any;
    productIndex: number;
    setModal: (isOpen: boolean) => void;
    businessUnitID: number
}

interface Tag {
    id: number;
    value: string;
    isDuplicate: boolean;
}

const TransactionsModalSerialsView = observer(({initialValue, productIndex, setModal, businessUnitID}: TransactionsModalSerialsViewProps) => {
    const {clearProductSerials, addProductSerials} = TransactionsModalStore;

    const {serialNumbers, setSerialNumbersFilter, loading} = ProductSerialNumbersStore;
    const [selectedOption, setSelectedOption] = useState(null);
    const [tags, setTags] = useState<Tag[]>([]);

    useEffect(() => {
        setSerialNumbersFilter({product_id: initialValue?.product_id, sold: false, business_unit_id: businessUnitID});
        const existingSerials = serialNumbers?.filter(serial => initialValue?.serial_ids?.
        includes(serial.id))?.map(({id, number}) => ({id: id, value: number, isDuplicate: false}));
        setTags(existingSerials!)
    }, [setSerialNumbersFilter]);

    const serialsOptions = serialNumbers?.filter((serial) =>
        !tags?.find(tag => tag.id === serial.id)).map(
        ({id, number}) => ({label: number, value: id})
    );

    const handleSelectSerial = (option: any) => {
        const tag: Tag = {
            id: option.value,
            value: option.label,
            isDuplicate: false
        }
        const updatedTags = [...tags!]
        updatedTags.push(tag);
        setTags(updatedTags);
        setSelectedOption(null);
    }

    const handleDeleteSerial = (tagID: number) => {
        handleRemoveTag(tagID)
    }

    // Handle removing a tag
    const handleRemoveTag = (id: number) => {
        setTags(prevTags => prevTags.filter(tag => tag.id !== id));
    };

    let serialList = tags?.map(
        ({ value, id}) => ({value, id})
    )

    const handleClearAllClick = () => {
        setTags([]);
        serialList = [];
    }

    const handleSubmit = () => {
        clearProductSerials(productIndex);
        addProductSerials(productIndex, serialList)
        setModal(false);
    }

    const handleCancelClick = () => {
        setTags([]);
        setModal(false);
    }

    return (
        <Layout vertical between={20}>
            <Layout hAlign={"space-between"}>
                <Layout>
                    <Text extent={'S'} color={tags?.length !== initialValue.quantity ? 'red' : 'unset'}>
                        {`Количество: ${tags?.length}`}
                    </Text>
                </Layout>
                <Layout>
                    <Button
                        onClick={handleClearAllClick}
                    >
                        Очистить все
                    </Button>
                </Layout>
            </Layout>
            <Layout widthSize={'100%'} vertical between={5}>
                <Layout widthSize={'100%'}>
                    <Select
                        showSearch
                        options={serialsOptions}
                        showArrow={false}
                        notFoundContent={loading ? <Spin size={'small'}/> :
                            <Layout centered> Данных нет</Layout>}
                        filterOption={(inputValue, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase())
                        }
                        placeholder={'Выберите серийный номер'}
                        onChange={(_, option) => {
                            handleSelectSerial(option)
                        }}
                        style={{
                            width: '100%',
                        }}
                        value={selectedOption}
                        loading={loading}
                    />
                </Layout>
                <Layout
                    style={{
                        border: '2px solid #ddd',
                        padding: '8px',
                        marginTop: '10px',
                        width: '100%',
                        borderRadius: '10px',
                        backgroundColor: "rgba(0, 0, 0, .03)",
                        color: '#333'
                    }}
                >
                    <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '10px'}}>
                        {tags?.map((tag, _) => (
                            <Layout
                                key={tag.id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: '#e0e0e0',
                                    borderRadius: '8px',
                                    padding: '5px',
                                    margin: '5px',
                                    color: tag.isDuplicate ? "red" : "black",
                                }}
                            >
                                <Text extent={'S'} color={'#555'}>{tag.value}</Text>
                                <button
                                    onClick={() => handleDeleteSerial(tag.id)}
                                    style={{
                                        marginLeft: '5px',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        color: '#888',
                                    }}
                                >
                                    ×
                                </button>
                            </Layout>
                        ))}
                    </div>
                </Layout>
            </Layout>

            <Layout centered>
                <Layout vAlign={"center"} between={20}>
                    <Button
                        background={'#888'}
                        onClick={handleCancelClick}
                    >
                        Отменить
                    </Button>
                    <Button
                        onClick={handleSubmit}
                    >
                        Потвердить
                    </Button>
                </Layout>
            </Layout>
        </Layout>
    );
});

export default TransactionsModalSerialsView;