import { observer } from 'mobx-react';
import AppStore from '../../app.store';
import { HeaderLayout } from '../../styles/header-layout';
import { ProfileIcon } from '../../styles/profile-icon';
import { Wrapper, Main } from '../../styles/wrapper';
import ModalView from '../modal/modal.view';
import { Modal as AntModal } from 'antd';
import { useEffect, useState } from 'react';
import Modal from '../modal/modal.view';
import ConfirmLogout from '../confirm/confirm.logout';
import { useNavigate } from 'react-router-dom';
import { LogoutItem } from '../confirm/confirm.logout';
import SubMenu from './subMenu';
import SettingsCreateProfile from './settings.modal/settings.create-profile';
import SettingsCreatePassword from './settings.modal/settings.create-password';
import { Image } from '../../styles/image';
import BELL from '../../assets/icons/bell.svg';
import { ThemeProvider } from 'styled-components';
import FirebaseStore from './firebase.store';
import DestroyAllStore from "../../utils/destroyAll";

const Header = observer(() => {
  const { isNavOpen, userData } = AppStore;
  const [modalActive, setModalActive] = useState(false);
  const [confirmLogoutModal, setConfirmLogoutModal] = useState<LogoutItem>({
    isModalOpen: false,
  });

  const { setIsLoggedIn } = AppStore;
  const navigate = useNavigate();
  const {destroyAll} = DestroyAllStore;

  function deleteToken() {
    destroyAll();
    localStorage.removeItem('visor-access-token');
    localStorage.removeItem('visor-refresh-token');
    setIsLoggedIn(false);
    navigate('/login');
  }

  const handleLogout = (e: { stopPropagation: () => void }) => {
    destroyAll();
    setConfirmLogoutModal({ isModalOpen: true });
    setModalActive(false);
  };

  const [editSettingsProfile, setEditSettingsProfile] = useState(false);
  const [editSettingsPassword, setEditSettingsPassword] = useState(false);

  const handleProfileStore = (e: { stopPropagation: () => void }) => {
    setEditSettingsProfile(true);
  };

  useEffect(() => {
    function handleWindowClick(event: MouseEvent) {
      const modalEl = document.getElementById('subMenu');
      const profileEl = document.getElementById('profileIcon');
      if (
        modalEl &&
        !modalEl.contains(event.target as Node) &&
        profileEl &&
        !profileEl.contains(event.target as Node)
      ) {
        setModalActive(false);
      }
    }

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, [modalActive]);

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const { firebaseapi, firebasePageInit } = FirebaseStore;

  useEffect(() => {
    const controller = new AbortController();
    void firebasePageInit(controller.signal);

    return () => {
      controller.abort();
    };
  }, [firebasePageInit]);

  const unreadNotifications = firebaseapi?.filter(
    (item) => item.read === false
  );

  const handleSendOneId = (e: any, id: number) => {
    e.preventDefault();
    void FirebaseStore.sendNotesIds([id]);
  };

  return (
    <>
      <HeaderLayout isNavOpen={isNavOpen}>
        <Wrapper withoutMaxWidth>
          <ThemeProvider theme={{}}>
            <Image src={BELL} bell={true} extent={'18px'} onClick={showModal} />
            {unreadNotifications?.length ? (
              <span
                style={{
                  background: '#f00',
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  position: 'relative',
                  right: '8px',
                  top: '-14px',
                  fontSize: '8px',
                  display: 'inlineBlock',
                  paddingLeft: '7px',
                  color: 'red',
                }}
              >
                .
              </span>
            ) : null}
            <AntModal
              open={visible}
              onCancel={handleCancel}
              title="Уведомления"
              style={{ top: 70, marginRight: 85 }}
              width={370}
              footer={null}
            >
              <hr
                style={{
                  height: '2px',
                  borderWidth: 0,
                  color: 'gray',
                  backgroundColor: 'gray',
                }}
              />
              <Main style={{ maxHeight: 300, overflowY: 'scroll' }}>
                <div className="main-modal">
                  {firebaseapi?.map((firebase, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '15px',
                        borderRadius: '5px',
                        backgroundColor: firebase.read ? 'transparent' : '#ccc',
                      }}
                      onClick={(e) => handleSendOneId(e, firebase.id)}
                    >
                      <div className="modal_data">
                        <div className="modal_title">{firebase.title}</div>
                        <div className="modal_body">{firebase.body}</div>
                      </div>
                      <div style={{ width: 90 }}>{firebase.formatted_date}</div>
                    </div>
                  ))}
                </div>
              </Main>
            </AntModal>
          </ThemeProvider>
        </Wrapper>
        <Wrapper withoutMaxWidth>
          <ProfileIcon
            id="profileIcon"
            onClick={() => setModalActive(true)}
            centered
          >
            {userData?.name[0]}
          </ProfileIcon>
        </Wrapper>
        {modalActive && (
          <SubMenu
            handleLogout={handleLogout}
            handleProfileStore={handleProfileStore}
          />
        )}
        {editSettingsProfile && (
          <Modal
            title={'Профиль'}
            onClose={() => {
              setEditSettingsProfile(false);
            }}
          >
            <SettingsCreateProfile
              setModal={setEditSettingsProfile}
              setChangePasswordModal={setEditSettingsPassword}
            ></SettingsCreateProfile>
          </Modal>
        )}
        {editSettingsPassword && (
          <Modal
            title={'Изменение пaроля'}
            onClose={() => {
              setEditSettingsPassword(false);
            }}
          >
            <SettingsCreatePassword
              setModal={setEditSettingsPassword}
            ></SettingsCreatePassword>
          </Modal>
        )}
      </HeaderLayout>
      {confirmLogoutModal.isModalOpen && (
        <ModalView
          title="Выход из приложения"
          onClose={() => {
            setConfirmLogoutModal({ isModalOpen: false });
          }}
        >
          <ConfirmLogout
            warningText={`Подтвердите данное действие?`}
            setModal={setConfirmLogoutModal}
            callback={deleteToken}
            isLoading={false}
          />
        </ModalView>
      )}
    </>
  );
});

export default Header;
