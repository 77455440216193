import {ExchangeRateModel} from "../exchange.rates.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";
import ownerSettingsStore from "../../owner.settings/owner.settings.store";

const {businessSettings} = AppStore;

const formInitialValue: ExchangeRateModel = {
    id: 0,
    base_currency: '',
    target_currency: businessSettings?.main_currency!,
    rate: 0,
    period_text: '',
    from_date: '',
    from_time: '',
    to_date: '',
    to_time: '',
}
const loadingInitialState: boolean = false;

class ExchangeRatesModalStore {
    public form = formInitialValue;
    public createExchangeRateLoading = loadingInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this)
    }

    setLoading(loading: boolean) {
        this.createExchangeRateLoading = loading;
    }

    public setForm = (form: any) => {
        this.form = form;
    }

    public destroyExchangeRateFrom = () => {
        this.form = formInitialValue;
    }

    public initCreateEditExchangeRatePage = (initialValue: ExchangeRateModel | null) => {
        if (initialValue) {
            this.form = initialValue;
        }
    }

    public createEditExchangeRateReq = async (edit: boolean) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {Authorization: `Bearer ${getToken()!}`};
        const payload = {
            id: this.form.id,
            base_currency: this.form.base_currency,
            target_currency: this.form.target_currency,
            rate: Number(this.form.rate),
            valid_from_text: `${this.form.from_date} ${this.form.from_time}`,
            valid_to_text: `${this.form.to_date} ${this.form.to_time}`,
        }

        try {
            let res;
            if (edit) {
                res = await axios.put(api.exchange_rates, payload, {headers});
            } else {
                res = await axios.post(api.exchange_rates, payload, {headers});
            }

            this.message = res?.data.message;
            toast.success(this.message);
            return res;
        } catch (e: any) {
            this.error = e?.response?.data?.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new ExchangeRatesModalStore();