import {Date, Required, Select, TextInput} from '../../styles/form';
import {Layout} from '../../styles/layout';
import {Text} from '../../styles/text';
import {inputBackground, inputColor, inputPadding, modalTextColor, primaryColor} from '../../utils/variables';
import {DatePicker, Switch, Tooltip} from 'antd';
import {Image} from "../../styles/image";
import InfoIcon from "../../assets/icons/information.svg";
import React from "react";
import dayjs from "dayjs";


export const renderInputs = (form: any, inputs: any[], onChange: any) =>
    inputs.map((input, index) => {
        const {field, fieldName, placeholder, required, type, selectValueType, hasTooltip, tooltipText, disabled, max_length} =
            input;
        switch (type) {
            case 'text':
                return (
                    <Layout key={index} between={2} vAlign="center">
                        <Layout widthSize="35%" hAlign={"space-between"} vAlign={"center"}>
                            <Layout>
                                <Text extent="S" color={modalTextColor}>
                                    {placeholder}
                                </Text>
                                {required && <Required>*</Required>}
                            </Layout>
                            {hasTooltip &&
                                <Tooltip
                                    title={tooltipText}
                                    color={primaryColor}
                                >
                                    <Image
                                        src={InfoIcon}
                                        height={'18px'}
                                        width={'18px'}
                                        style={{
                                            filter:
                                                'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                        }}
                                    />
                                </Tooltip>
                            }
                        </Layout>
                        <Layout widthSize="65%">
                            <TextInput
                                maxLength={max_length ? max_length : 'unset'}
                                type={fieldName === 'password' ? 'password' : 'text'}
                                value={field}
                                disabled={disabled}
                                onChange={(e) =>
                                    onChange({...form, [fieldName]: e.target.value})
                                }
                            />
                        </Layout>
                    </Layout>
                );
            case 'number':
                return (
                    <Layout key={index} between={2} vAlign="center">
                        <Layout widthSize="35%" hAlign={"space-between"} vAlign={"center"}>
                            <Layout>
                                <Text extent="S" color={modalTextColor}>
                                    {placeholder}
                                </Text>
                                {required && <Required>*</Required>}
                            </Layout>
                            {hasTooltip &&
                                <Tooltip
                                    title={tooltipText}
                                    color={primaryColor}
                                >
                                    <Image
                                        src={InfoIcon}
                                        height={'18px'}
                                        width={'18px'}
                                        style={{
                                            filter:
                                                'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                        }}
                                    />
                                </Tooltip>
                            }
                        </Layout>
                        <Layout widthSize="65%">
                            <TextInput
                                type={'text'}
                                value={field}
                                onChange={(e) =>
                                    onChange({...form, [fieldName]: +e.target.value})
                                }
                            />
                        </Layout>
                    </Layout>
                );
            case 'select':
                return (
                    <Layout key={index} between={2} vAlign="center">
                        <Layout widthSize="35%" hAlign={"space-between"} vAlign={"center"}>
                            <Layout>
                                <Text extent="S" color={modalTextColor}>
                                    {placeholder}
                                </Text>
                                {required && <Required>*</Required>}
                            </Layout>
                            {hasTooltip &&
                                <Tooltip
                                    title={tooltipText}
                                    color={primaryColor}
                                >
                                    <Image
                                        src={InfoIcon}
                                        height={'18px'}
                                        width={'18px'}
                                        style={{
                                            filter:
                                                'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                        }}
                                    />
                                </Tooltip>
                            }
                        </Layout>
                        <Layout widthSize="65%" style={{maxWidth: '100%'}}>
                            <Select
                                extent={'S'}
                                disabled={disabled}
                                onChange={(e) => {
                                    onChange({
                                        ...form,
                                        [fieldName]:
                                            selectValueType === 'string'
                                                ? e.target.value
                                                : Number(e.target.value),
                                    });
                                }}
                                value={field || 0}
                            >
                                <option key={0} disabled value={0}>
                                    Выберите {placeholder.toLowerCase()}
                                </option>
                                {input.options?.map((option: any, index: any) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>
                        </Layout>
                    </Layout>
                );
            case 'switch':
                return (
                    <Layout vAlign="center" hAlign="space-between">
                        <Layout widthSize={'35%'} hAlign={"space-between"} vAlign={"center"}>
                            <Layout>
                                <Text extent="S" color={modalTextColor}>
                                    {placeholder}
                                </Text>
                            </Layout>
                            {hasTooltip &&
                                    <Tooltip
                                        title={tooltipText}
                                        color={primaryColor}
                                    >
                                        <Image
                                            src={InfoIcon}
                                            height={'18px'}
                                            width={'18px'}
                                            style={{
                                                filter:
                                                    'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                            }}
                                        />
                                    </Tooltip>
                            }
                        </Layout>
                        <Layout>
                            <Switch
                                checked={field}
                                onChange={(e) => onChange({...form, [fieldName]: e})}
                            />
                        </Layout>

                    </Layout>
                );
            case 'date':
                return (
                    <Layout key={index} between={2} vAlign="center">
                        <Layout widthSize="35%" hAlign={"space-between"} vAlign={"center"}>
                            <Layout>
                                <Text extent="S" color={modalTextColor}>
                                    {placeholder}
                                </Text>
                                {required && <Required>*</Required>}
                            </Layout>
                            {hasTooltip &&
                                <Tooltip
                                    title={tooltipText}
                                    color={primaryColor}
                                >
                                    <Image
                                        src={InfoIcon}
                                        height={'18px'}
                                        width={'18px'}
                                        style={{
                                            filter:
                                                'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                        }}
                                    />
                                </Tooltip>
                            }
                        </Layout>
                        <Layout widthSize="65%">
                            <Layout widthSize='100%'>
                                    <DatePicker
                                        onChange={(e) => {
                                            onChange({...form, [fieldName]: e?.format('YYYY-MM-DD')})
                                        }}
                                        placeholder='Выберите дату'
                                        value={field ? dayjs(field) : null}
                                    />

                            </Layout>
                        </Layout>
                    </Layout>
                );
            default:
                return null;
        }
    });
