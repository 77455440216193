import {observer} from 'mobx-react';
import {Layout} from '../../../styles/layout';
import {AddButton} from '../../../styles/table-header';
import {useState} from 'react';
import ModalView from '../../../components/modal/modal.view';
import CreateOperationView from './create.operation.view';
import CreateOperationStore from "./create.operation.store";
import TransactionsModalView from "../transactions.modal/transactions.modal.view";
import AccountModalView from "../../../components/modal/account.modal.view";
import {TableContainer, TableContainerMobile} from "../../../styles/table";
import TransactionsModalStore from "../transactions.modal/transactions.modal.store";
import {TransactionsModel} from "../../transactions/transactions.model";

interface OperationViewProps {
    initialValue?: TransactionsModel | null;
}

const OperationView = observer(({initialValue}: OperationViewProps) => {
    const [modal, setModal] = useState(!!initialValue);
    const {destroy} = CreateOperationStore;
    const {destroyTransactionModal} = TransactionsModalStore

    const handleCloseModal = () => {
        setModal(false);
        destroy();
        destroyTransactionModal();
    };


    return (
        <Layout>
            {!initialValue && (
                <AddButton type="button" onClick={() => setModal(true)}>
                    Создать операцию
                </AddButton>
            )}
            {modal && (
                <AccountModalView fullScreen title={'Создать операцию'} onClose={handleCloseModal}>
                    <>
                        <TableContainer style={{boxShadow: 'unset', borderRadius: 0}}>
                            <TransactionsModalView initialValue={initialValue!} setModal={setModal}/>
                        </TableContainer>
                        <TableContainerMobile>
                            <CreateOperationView initialValue={initialValue!} setModal={setModal}/>
                        </TableContainerMobile>
                    </>
                </AccountModalView>
            )}
        </Layout>
    );
});

export default OperationView;
