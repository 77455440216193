import {observer} from "mobx-react";
import {useEffect, useRef, useState} from "react";
import {Chevron, CollapsibleContainer, Content, Summary} from "../../styles/collapse";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {modalTextColor} from "../../utils/variables";

interface CollapseViewProps {
    title: string;
    description: string;
    children: JSX.Element;
    openByDefault?: boolean;
}

const Collapse = observer(({title, description, children, openByDefault = false}: CollapseViewProps) => {
    const [isOpen, setIsOpen] = useState(openByDefault);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (contentRef.current) {
            const fullHeight = contentRef.current.scrollHeight;
            setContentHeight(fullHeight);
        }
    }, [children]);

    return (
        <CollapsibleContainer>
            <Summary onClick={() => {
                setIsOpen(!isOpen)
            }}>
                <Layout between={20} vAlign={"center"}>
                    <Layout>{title}</Layout>
                    <Layout>
                        <Text
                            color={modalTextColor}
                            extent={'S'}
                            weight={'normal'}
                        >
                            {description}
                        </Text>
                    </Layout>
                </Layout>

                <Chevron isOpen={isOpen}>▼</Chevron>
            </Summary>
            <Content
                contentHeight={contentHeight}
                ref={contentRef}
                isOpen={isOpen}
            >
                <div style={{padding: '1rem'}}>{children}</div>
            </Content>
        </CollapsibleContainer>
    );
});

export default Collapse;